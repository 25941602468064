import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import RenderCourses from '../pages/RenderCourses';
import Personal from '../pages/Personal';
import CourseInformations from '../pages/CourseInformations';

export default function Routes({appProps}) {
    // /courses renderiza os cursos do usuario logado
    // /personal/:user_id renderiza o perfil do usuario cujo id é user_id
    // /course/:course_id renderiza as informações do curso cujo id é course_id
    return (
            <Switch>
                <Route path="/user/courses/:folder_id/:isAllCoursesScope" exact render={props => appProps.authenticatedUser ?
                                                        <RenderCourses key='folderCourses'/>
                                                        : <Redirect to='/courses'/> }/>
                <Route path="/user/courses" exact render={props => appProps.authenticatedUser ?
                                                        <RenderCourses key='cadastratedCourses' /> 
                                                        : <Redirect to='/login' /> }/>
                <Route path="/user/personal" exact render={props => appProps.authenticatedUser ?
                                                        <Personal key='personal' />
                                                        : <Redirect to='/login' /> }/>
                <Route path="/user/course/:course_id" exact render={props => appProps.authenticatedUser ?
                                                        <CourseInformations />
                                                        : <Redirect to='/login' /> }/>
                <Route path="/user"  render={props => <Redirect to='/user/courses' /> }/>
            </Switch>
    )
}