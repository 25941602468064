import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { formatDate } from "../../utils/formatDate";
import "./styles.css";
import "../../utils/axiosInterceptor";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import Loader from "react-loader-spinner";
import { GoSearch } from "react-icons/go";
import { DebounceInput } from "react-debounce-input";
import { sortCertificate } from "../../utils/sortCertificate";
import api from "../../services/api";

// Remova a condição que retorna o loader de forma antecipada e renderize-o junto com a tabela:
export default function RenderCertificates(props) {
  const isAdmin = true;
  const [certificates, setCertificates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState("Data");
  const [query, setQuery] = useState("");

  // Carregar os certificados – se houver pesquisa, use o endpoint de query
  useEffect(() => {
    setLoading(true);
    if (query) {
      api
        .get(`/certificates/q?search=${query}&page=${page}`)
        .then((response) => {
          const data =
            response.data.certificates &&
            Array.isArray(response.data.certificates)
              ? response.data.certificates
              : [];
          setCertificates(data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Erro na pesquisa:", error);
          setCertificates([]);
          setLoading(false);
        });
    } else {
      api
        .get(`/certificates?page=${page}`)
        .then((response) => {
          const data = response.data;
          setCertificates(Array.isArray(data) ? data : []);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Erro ao carregar certificados:", error);
          setCertificates([]);
          setLoading(false);
        });
    }
  }, [page, query]);

  // Se o critério de ordenação for "Data", assumimos que o backend já entrega em ordem decrescente.
  // Para outros critérios, aplicamos a função sortCertificate.
  const sortedCertificates = useMemo(() => {
    if (sortBy === "Data") return certificates;
    return sortCertificate(certificates, sortBy);
  }, [certificates, sortBy]);

  return (
    <div className="userInformations userInformationsAll">
      <div className="pageTitle">
        <span>Todos os Certificados</span>
      </div>
      <div className="pageContent">
        <div className="searchOrder">
          <div className="searchBar">
            <DebounceInput
              debounceTimeout={350}
              type="text"
              value={query}
              id="search"
              placeholder="Pesquisar Certificado"
              label="search certificates"
              onChange={(e) => setQuery(e.target.value)}
            />
            <GoSearch
              size={30}
              style={{
                margin: "auto",
                marginLeft: "-35px",
                color: "#a3a7ae",
              }}
            />
          </div>
          <div className="selectBoxContainer">
            <span>Ordenar Por</span>
            <select value={sortBy} onChange={(e) => setSortBy(e.target.value)}>
              <option value="ID">ID</option>
              <option value="Nome">Nome</option>
              <option value="Curso">Curso</option>
              <option value="Data">Data</option>
            </select>
          </div>
        </div>
        <div className="listTable">
          {loading ? (
            <div className="loaderDiv">
              <Loader type="ThreeDots" color={"#863231"} />
            </div>
          ) : sortedCertificates.length > 0 ? (
            <table className="table">
              <tbody>
                <tr>
                  <th>
                    <span>ID</span>
                  </th>
                  <th>
                    <span>Nome</span>
                  </th>
                  <th>
                    <span>Nome do Curso</span>
                  </th>
                  <th>
                    <span>Emissão</span>
                  </th>
                </tr>
                {sortedCertificates.map((certificate) => (
                  <tr key={certificate.id}>
                    <td>
                      <span>
                        {certificate.id}
                        <br />
                      </span>
                    </td>
                    <td>
                      {isAdmin ? (
                        <Link
                          to={`/admin/personal/${certificate.user_id}`}
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          {certificate.user_name}
                        </Link>
                      ) : (
                        <span>{certificate.user_name}</span>
                      )}
                    </td>
                    <td>
                      <span>
                        {certificate.course_name}
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        {formatDate(certificate.issued_at)}
                        <br />
                      </span>
                    </td>
                  </tr>
                ))}
                {sortedCertificates.length !== 0 && (
                  <div className="navigation">
                    <div className="previous">
                      {page !== 1 ? (
                        <MdKeyboardArrowLeft
                          size={30}
                          color={"#537e43"}
                          cursor={"pointer"}
                          onClick={() => {
                            setPage(page - 1);
                          }}
                        />
                      ) : (
                        <MdKeyboardArrowLeft size={30} color={"black"} />
                      )}
                    </div>
                    <div className="next">
                      {sortedCertificates.length >= 10 ? (
                        <MdKeyboardArrowRight
                          size={30}
                          style={{ color: "#537e43" }}
                          cursor={"pointer"}
                          onClick={() => {
                            setPage(page + 1);
                          }}
                        />
                      ) : (
                        <MdKeyboardArrowRight size={30} color={"black"} />
                      )}
                    </div>
                  </div>
                )}
              </tbody>
            </table>
          ) : (
            "Nenhum certificado encontrado"
          )}
        </div>
      </div>
    </div>
  );
}
