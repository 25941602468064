import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import RenderCourses from "../pages/RenderCourses";
import Personal from "../pages/Personal";
import RenderUsers from "../pages/RenderUsers";
import RegisterCourse from "../pages/RegisterCourse";
import SearchResults from "../pages/SearchResults";
import RegisterLogo from "../pages/RegisterLogo";
import RegisterSignature from "../pages/RegisterSignature";
import RenderLeagues from "../pages/RenderLeagues";
import RegisterLeague from "../pages/RegisterLeague";
import LeagueInformations from "../pages/LeagueInformations";
import RenderLogos from "../pages/RenderLogos";
import RenderSignatures from "../pages/RenderSignatures";
import RenderCertificates from "../pages/AllCertificates";

export default function Routes({ appProps }) {
  // /courses/:renderAll renderiza todos os cursos existentes
  // /personal/:user_id renderiza o perfil do usuario cujo id é user_id
  // /user/all renderiza todos os usuarios em formato de tabela
  // /registerCurse renderiza o formulario para cadastro de curso
  // /course/:course_id renderiza as informações do curso cujo id é course_id
  // /searchResults renderiza os resultados da busca feitas pela barra de pesquisa
  // /logo renderiza um formulario para criação de um logo
  // /signature renderiza um formulario para criação de uma assinatura
  // /certification renderiza o certificado para o usuario e curso passado como query param
  // /registerLeague renderiza o formulario para cadastro de liga
  // /league/:league_id renderiza as informações da liga cujo id é league_id
  return (
    <Switch>
      <Route
        path="/admin/courses/:renderAll"
        exact
        render={(props) =>
          appProps.authenticatedAdmin ? (
            <RenderCourses key="allCourses" />
          ) : (
            <Redirect to="/courses" />
          )
        }
      />
      <Route
        path="/admin/personal/:user_id"
        exact
        render={(props) =>
          appProps.authenticatedAdmin ? (
            <Personal key="userProfile" />
          ) : (
            <Redirect to="/courses" />
          )
        }
      />
      <Route
        path="/admin/user/all"
        exact
        render={(props) =>
          appProps.authenticatedAdmin ? (
            <RenderUsers key="allUsers" showAll={true} />
          ) : (
            <Redirect to="/courses" />
          )
        }
      />
      <Route
        path="/admin/logo/all"
        exact
        render={(props) =>
          appProps.authenticatedAdmin ? (
            <RenderLogos />
          ) : (
            <Redirect to="/courses" />
          )
        }
      />
      <Route
        path="/admin/registerCourse"
        exact
        render={(props) =>
          appProps.authenticatedAdmin ? (
            <RegisterCourse />
          ) : (
            <Redirect to="/courses" />
          )
        }
      />
      <Route
        path="/admin/searchResults"
        render={(props) =>
          appProps.authenticatedAdmin ? (
            <SearchResults />
          ) : (
            <Redirect to="/courses" />
          )
        }
      />
      <Route
        path="/admin/logo"
        render={(props) =>
          appProps.authenticatedAdmin ? (
            <RegisterLogo />
          ) : (
            <Redirect to="/courses" />
          )
        }
      />
      <Route
        path="/admin/signature/all"
        exact
        render={(props) =>
          appProps.authenticatedAdmin ? (
            <RenderSignatures />
          ) : (
            <Redirect to="/courses" />
          )
        }
      />
      <Route
        path="/admin/signature"
        render={(props) =>
          appProps.authenticatedAdmin ? (
            <RegisterSignature />
          ) : (
            <Redirect to="/courses" />
          )
        }
      />
      <Route
        path="/admin/leagues"
        exact
        render={(props) =>
          appProps.authenticatedAdmin ? (
            <RenderLeagues />
          ) : (
            <Redirect to="/courses" />
          )
        }
      />
      <Route
        path="/admin/registerLeague"
        exact
        render={(props) =>
          appProps.authenticatedAdmin ? (
            <RegisterLeague />
          ) : (
            <Redirect to="/courses" />
          )
        }
      />
      <Route
        path="/admin/league/:league_id"
        exact
        render={(props) =>
          appProps.authenticatedAdmin ? (
            <LeagueInformations />
          ) : (
            <Redirect to="/courses" />
          )
        }
      />
      <Route
        path="/admin/certificates/issued"
        exact
        render={(props) =>
          appProps.authenticatedAdmin ? (
            <RenderCertificates />
          ) : (
            <Redirect to="/courses" />
          )
        }
      />
      <Route
        path="/admin"
        render={(props) => <Redirect to="/user/courses" />}
      />
    </Switch>
  );
}
