import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./styles.css";
import api from "../../services/api";
import "../../utils/axiosInterceptor";
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdClose,
} from "react-icons/md";
import Loader from "react-loader-spinner";
import { GoSearch } from "react-icons/go";
import { DebounceInput } from "react-debounce-input";
import { toast } from "react-toastify";

import { sortList } from "../../utils/sortList";

export default function RenderUsers(props) {
  const showAll = props.showAll;
  const renderUsersAgain = props.renderUsersAgain;
  const league_id = props.league_id;
  const course_id = props.course_id;
  const user_id = props.user_id;
  const leagueUserCourse = props.leagueUserCourse;
  const removeFromCourseButton = props.removeFromCourseButton;
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [sortBy, setSortBy] = useState("ID");
  const [query, setQuery] = useState("");

  async function setDefault(id) {
    await api
      .get("/user/setDefault", {
        params: {
          id: id,
        },
      })
      .then((response) => {
        let auxUsers = users;
        console.log(auxUsers.find((user) => user.id === id));
        auxUsers.find((user) => user.id === id).isDefaulter = !auxUsers.find(
          (user) => user.id === id
        ).isDefaulter;
        setUsers([...auxUsers]);
        toast.success("Aluno alterado com sucesso", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      })
      .catch((error) => {
        toast.success("Não foi possível alterar o aluno", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      });
  }

  useEffect(() => {
    setUsers(sortList(users, sortBy));
    // eslint-disable-next-line
  }, [sortBy]);

  //Renderiza todos os usuarios ou os usuarios de um curso
  useEffect(() => {
    if (showAll) {
      if (users.length === 0) setLoading(true);
      api.get(`/searchUser?search=${query}&page=${page}`).then((response) => {
        setUsers(sortList(response.data.users, sortBy));
        setLoading(false);
      });
    } else if (leagueUserCourse) {
      setLoading(true);
      api
        .get(`/leagueUsers`, {
          params: {
            user_id: user_id,
            course_id: course_id,
            page: page,
          },
        })
        .then((response) => {
          setUsers(response.data.users);
          setLoading(false);
        });
    } else if (course_id) {
      setLoading(true);
      api
        .get(`/courseUsers`, {
          params: {
            course_id: course_id,
            page: page,
          },
        })
        .then((response) => {
          setUsers(response.data.users);
          setLoading(false);
        });
    } else if (league_id) {
      setLoading(true);
      api
        .get(`/leagueUsers`, {
          params: {
            league_id: league_id,
            page: page,
          },
        })
        .then((response) => {
          setUsers(response.data.users);
          setLoading(false);
        });
    }

    // eslint-disable-next-line
  }, [
    course_id,
    showAll,
    page,
    renderUsersAgain,
    league_id,
    leagueUserCourse,
    user_id,
    query,
  ]);

  function removeUserFromCourse(e, removed_user_id) {
    e.preventDefault();

    if (
      window.confirm("Tem certeza de que deseja remover este aluno do curso?")
    ) {
      api
        .delete(`/removeUserFromCourse/${removed_user_id}/${course_id}`)
        .then((response) => {
          let auxUsers = users;
          let indexUser = auxUsers.indexOf(
            auxUsers.find((user) => user.id === removed_user_id)
          );
          auxUsers.splice(indexUser, 1);
          setUsers([...auxUsers]);
          toast.success("Usuário deletado do curso com sucesso", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
        })
        .catch((err) => {
          toast.error("Não foi possível deletar o usuário do curso", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
        });
    }
  }

  if (loading)
    return (
      <div className="loaderDiv">
        {" "}
        <Loader type="ThreeDots" color={"#863231"} />{" "}
      </div>
    );
  return (
    <div className="userInformations userInformationsAll">
      {!course_id && !league_id ? (
        <div className="pageTitle">
          <span>{showAll ? "Todos os Usuarios" : "Informações Pessoais"}</span>
        </div>
      ) : null}
      <div className="pageContent">
        <div className="searchOrder">
          {showAll ? (
            <div className="searchBar">
              <DebounceInput
                debounceTimeout={350}
                type="text"
                id="search"
                placeholder="Pesquisar Usuário"
                label="search users"
                onChange={(e) => setQuery(e.target.value)}
              />
              <GoSearch
                size={30}
                style={{
                  margin: "auto",
                  marginLeft: "-35px",
                  color: "#a3a7ae",
                }}
              />
            </div>
          ) : null}
          <div className="selectBoxContainer">
            <span>Ordernar Por</span>
            <select onChange={(e) => setSortBy(e.target.value)}>
              <option selected="true" value={"ID"}>
                ID
              </option>
              <option value={"Nome"}>Nome</option>
            </select>
          </div>
        </div>
        <div className="listTable">
          {users.length > 0 ? (
            <table className="table">
              <tbody>
                <tr>
                  <th>
                    <span>ID</span>
                  </th>
                  <th>
                    <span>Nome</span>
                  </th>
                  <th>
                    <span>Email</span>
                  </th>

                  <th>
                    <span>Inadimplente</span>
                  </th>
                  {course_id ? (
                    <th>
                      <span>Qualidade</span>
                    </th>
                  ) : null}
                  <th>
                    <span>Alterar Situação</span>
                  </th>

                  {removeFromCourseButton ? (
                    <>
                      <th>
                        <span>Remover</span>
                      </th>
                    </>
                  ) : null}
                </tr>
                {users.map((user) => (
                  <tr key={user.id}>
                    <td>
                      <span>
                        {user.id}
                        <br />
                      </span>
                    </td>

                    <td>
                      <Link to={`/admin/personal/${user.id}`}>
                        {user.name}
                        <br />
                      </Link>
                    </td>

                    <td>
                      <span>
                        {user.email}
                        <br />
                      </span>
                    </td>

                    <td>
                      <span>{user.isDefaulter ? "Sim" : "Não"}</span>
                    </td>
                    {course_id ? (
                      <>
                        <td>
                          <span>{user.user_quality}</span>
                        </td>
                      </>
                    ) : null}

                    {!user.isAdmin ? (
                      <td>
                        <button
                          onClick={() => {
                            setDefault(user.id);
                          }}
                        >
                          Alterar
                        </button>
                      </td>
                    ) : null}

                    {removeFromCourseButton ? (
                      <>
                        <td>
                          <MdClose
                            size={30}
                            className="removeFromCourse"
                            color={"#ffffff"}
                            cursor={"pointer"}
                            onClick={(e) => removeUserFromCourse(e, user.id)}
                          ></MdClose>
                        </td>
                      </>
                    ) : null}
                  </tr>
                ))}
                {users.length !== 0 && (
                  <div className="navigation">
                    <div className="previous">
                      {page !== 0 ? (
                        <MdKeyboardArrowLeft
                          size={30}
                          color={"#537e43"}
                          cursor={"pointer"}
                          onClick={() => {
                            setPage(page - 1);
                          }}
                        />
                      ) : (
                        <MdKeyboardArrowLeft size={30} color={"black"} />
                      )}
                    </div>

                    <div className="next">
                      {users.length >= 10 ? (
                        <MdKeyboardArrowRight
                          size={30}
                          style={{ color: "#537e43" }}
                          cursor={"pointer"}
                          onClick={() => {
                            setPage(page + 1);
                          }}
                        />
                      ) : (
                        <MdKeyboardArrowRight size={30} color={"black"} />
                      )}
                    </div>
                  </div>
                )}
              </tbody>
            </table>
          ) : (
            "Nenhum usuario encontrado"
          )}
        </div>
      </div>
    </div>
  );
}
