import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import "./styles.css";
import { MdMenu, MdExitToApp } from "react-icons/md";
//import {MdSearch} from 'react-icons/md';
import Cookies from "js-cookie";
import api from "../../services/api";
import {
  Accordion,
  Card,
  AccordionContext,
  useAccordionToggle,
} from "react-bootstrap";
import { FiUser, FiBookmark } from "react-icons/fi";
import { BiImageAlt } from "react-icons/bi";
import { AiOutlineEdit } from "react-icons/ai";
import { BsPeople } from "react-icons/bs";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";

export default function Sidebar(props) {
  const [isAdmin, setIsAdmin] = useState();
  const history = useHistory();

  useState(() => {
    setIsAdmin(JSON.parse(Cookies.get("User")).isAdmin);
  }, []);

  function tabActive(e) {
    const tabs = document.getElementsByClassName("menuLink");
    Array.from(tabs).forEach((tab) => tab.classList.remove("active"));
    this.classList.add("active");
  }

  useEffect(() => {
    const tabs = document.getElementsByClassName("menuLink");
    for (var i = 0; i < tabs.length; i++) {
      tabs[i].addEventListener("click", tabActive);
    }
  }, []);

  function logout() {
    api.get("/logout").then(() => {
      Cookies.remove("Authorization");
      Cookies.remove("User");
      Cookies.remove("refreshToken");
      history.push("/login");
    });
  }

  function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey)
    );

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
      <Accordion.Toggle
        as={Card.Header}
        variant="link"
        style={{
          backgroundColor: isCurrentEventKey
            ? "rgba(255,255,255,0.1)"
            : "transparent",
          borderLeft: isCurrentEventKey
            ? "5px solid rgba(134,50,49.1)"
            : "5px solid transparent",
        }}
        onClick={decoratedOnClick}
      >
        {children}
        {isCurrentEventKey ? (
          <IoIosArrowUp className="sidebarIcon toggle" />
        ) : (
          <IoIosArrowDown className="sidebarIcon toggle" />
        )}
      </Accordion.Toggle>
    );
  }

  //Barra de busca que ficava no menu lateral
  /*const [searchBar, setSearchBar] = useState('');
      const [option, setOption] = useState('user');

    function doSearch(e){
        e.preventDefault();
        if(searchBar !== '')
            history.push(`/admin/searchResults?searchBar=${searchBar}&option=${option}`);
    }

    const search = <div className="search">
                        <form id="searchForm" onSubmit={doSearch} autoComplete="off">
                            <div id="searchInputDiv">
                                <input id="searchInput" type="text" name="search" value={searchBar} 
                                onChange={e => setSearchBar(e.target.value)}/>

                                <select value={option} onChange={e => setOption(e.target.value)} name="options">
                                    <option value="user">Usuario</option>
                                    <option value="course">Curso</option>
                                </select>
                            </div>
                            <button><MdSearch  className="menuLink" size={30} color={'black'}/></button>
                        </form>
                    </div>
    */

  return (
    <div className="sidebarLeft">
      <div className="fixedMenu">
        <div className="hideShowMenu">
          <MdMenu
            size={50}
            style={{ float: "left", margin: "15px" }}
            cursor="pointer"
            onClick={() => {
              const sidebarContent =
                document.getElementsByClassName("menuContent")[0];
              const sidebar = document.getElementsByClassName("sidebarLeft")[0];
              const style = window.getComputedStyle(sidebarContent);
              const display = style.getPropertyValue("display");
              if (display === "none") {
                sidebarContent.style.display = "initial";
                sidebar.style.width = "initial";
                sidebar.style.borderRadius = "0px";
                sidebar.style.minWidth = "300px";
              } else {
                sidebarContent.style.display = "none";
                sidebar.style.width = "min-content";
                sidebar.style.minWidth = "0px";
              }
            }}
          />
        </div>
        <div className="menuContent">
          <ul className="menu">
            <Accordion id="menuAccordion" defaultActiveKey="0">
              <Card>
                <ContextAwareToggle eventKey="0">
                  <FiBookmark className="sidebarIcon" />
                  Cursos
                </ContextAwareToggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <Link className="menuLink active" to="/user/courses">
                      <li>
                        <span>Meus Cursos</span>
                      </li>
                    </Link>
                    {isAdmin ? (
                      <>
                        <Link className="menuLink" to="/admin/courses/all">
                          <li>
                            <span>Todos os Cursos</span>
                          </li>
                        </Link>
                        <Link className="menuLink" to="/admin/registerCourse">
                          <li>
                            <span>Cadastrar Curso</span>
                          </li>
                        </Link>
                        <Link
                          className="menuLink"
                          to="/admin/certificates/issued"
                        >
                          <li>
                            <span>Certificados Emitidos</span>
                          </li>
                        </Link>
                      </>
                    ) : null}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <ContextAwareToggle eventKey="1">
                  <FiUser className="sidebarIcon" />
                  Usuários
                </ContextAwareToggle>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <Link className="menuLink" to={`/user/personal`}>
                      <li>
                        <span>Informaçoes Pessoais</span>
                      </li>
                    </Link>
                    {isAdmin ? (
                      <Link className="menuLink" to="/admin/user/all">
                        <li>
                          <span>Todos os Usuarios</span>
                        </li>
                      </Link>
                    ) : null}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              {isAdmin ? (
                <>
                  <Card>
                    <ContextAwareToggle eventKey="2">
                      <BsPeople className="sidebarIcon" />
                      Ligas
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="2">
                      <Card.Body>
                        {isAdmin ? (
                          <>
                            <Link className="menuLink" to="/admin/leagues">
                              <li>
                                <span>Todas as Ligas</span>
                              </li>
                            </Link>
                            <Link
                              className="menuLink"
                              to="/admin/registerLeague"
                            >
                              <li>
                                <span>Cadastrar Liga</span>
                              </li>
                            </Link>
                          </>
                        ) : null}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <ContextAwareToggle eventKey="3">
                      <BiImageAlt className="sidebarIcon" />
                      Logos
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="3">
                      <Card.Body>
                        {isAdmin ? (
                          <>
                            <Link className="menuLink" to="/admin/logo/all">
                              <li>
                                <span>Todas os Logos</span>
                              </li>
                            </Link>
                            <Link className="menuLink" to="/admin/logo">
                              <li>
                                <span>Cadastrar Logo</span>
                              </li>
                            </Link>
                          </>
                        ) : null}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <ContextAwareToggle eventKey="4">
                      <AiOutlineEdit className="sidebarIcon" />
                      Assinaturas
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="4">
                      <Card.Body>
                        {isAdmin ? (
                          <>
                            <Link
                              className="menuLink"
                              to="/admin/signature/all"
                            >
                              <li>
                                <span>Todas as Assinaturas</span>
                              </li>
                            </Link>
                            <Link className="menuLink" to="/admin/signature">
                              <li>
                                <span>Cadastrar Assinatura</span>
                              </li>
                            </Link>
                          </>
                        ) : null}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </>
              ) : null}
            </Accordion>
            <div className="logout" onClick={logout}>
              <MdExitToApp className="sidebarIcon" />
              <span>Sair</span>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
}
