function sortById(array) {
  let sortedList = [...array];

  sortedList.sort((a, b) => {
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    return 0;
  });

  return sortedList;
}

function sortByName(array) {
  let sortedList = [...array];

  sortedList.sort((a, b) => {
    // Use empty string if user_name is null or undefined
    const aName = a.user_name ? a.user_name.toLowerCase() : "";
    const bName = b.user_name ? b.user_name.toLowerCase() : "";
    if (aName < bName) {
      return -1;
    }
    if (aName > bName) {
      return 1;
    }
    return 0;
  });

  return sortedList;
}

function sortByCourseName(array) {
  let sortedList = [...array];
  sortedList.sort((a, b) => {
    const aCourse = a.course_name ? a.course_name.toLowerCase() : "";
    const bCourse = b.course_name ? b.course_name.toLowerCase() : "";
    if (aCourse < bCourse) {
      return -1;
    }
    if (aCourse > bCourse) {
      return 1;
    }
    return 0;
  });
  return sortedList;
}

function sortByDate(array) {
  let sortedList = [...array];
  sortedList.sort((a, b) => new Date(b.issued_at) - new Date(a.issued_at));
  return sortedList;
}
export function sortCertificate(array, sortOption) {
  if (!Array.isArray(array)) return [];
  switch (sortOption) {
    case "Nome":
      return sortByName(array);
    case "Curso":
      return sortByCourseName(array);
    case "Data":
      return sortByDate(array);
    case "ID":
    default:
      return sortById(array);
  }
}
